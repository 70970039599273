import { css } from '@emotion/css';
import React from 'react';

import { GrafanaTheme2 } from '@grafana/data';
import { RadioButtonGroup, useStyles2 } from '@grafana/ui';

import { DataSource } from '../types';

const getStyles = (theme: GrafanaTheme2) => ({
  dataSourceItem: css`
    margin-left: ${theme.spacing(3)};
  `,
  deleteDataSourcesTitle: css`
    margin-top: ${theme.spacing(0)};
  `,
  deleteOptions: css`
    margin-top: ${theme.spacing(1)};
  `,
  dataSourceList: css`
    max-height: 150px;
    overflow-y: auto;
  `,
});

export interface DataSourcesWarningProps {
  dataSources: DataSource[];
  confirmDeletion: boolean;
  onConfirmDeleteDataSources: (confirm: boolean) => void;
}

export const DataSourcesWarning = ({
  dataSources,
  confirmDeletion,
  onConfirmDeleteDataSources,
}: DataSourcesWarningProps) => {
  const styles = useStyles2(getStyles);
  if (!dataSources || !dataSources.length) {
    return null;
  }

  const message =
    dataSources.length > 1 ? (
      <div className={styles.deleteDataSourcesTitle}>
        <br />
        <span>{`The following ${dataSources.length} data sources may stop working:`}</span>
        <ul className={styles.dataSourceList}>
          {dataSources.map((ds) => {
            return (
              <li className={styles.dataSourceItem} key={ds.id}>
                {ds.name}
              </li>
            );
          })}
        </ul>
        <br />
        <div>Do you want to delete them?</div>
      </div>
    ) : (
      <div
        className={styles.deleteDataSourcesTitle}
      >{`The data source "${dataSources[0].name}" will stop working. Do you want to delete it?`}</div>
    );

  const options = [
    { label: 'Yes', value: true },
    { label: 'No', value: false },
  ];

  return (
    <div className="datasource-warning-container">
      {message}
      <RadioButtonGroup
        options={options}
        onChange={(val) => onConfirmDeleteDataSources(val)}
        value={confirmDeletion}
        className={styles.deleteOptions}
      />
    </div>
  );
};
