import React from 'react';

import { Button, useStyles2 } from '@grafana/ui';

import { TokenWithValue } from '@grafana-cloud/access-policies';
import { DataSourceQueryError, useAddDataSourceMutation, useGetDataSourceByUIDQuery } from '../api/DataSourceApi';
import { useDataSource } from '../hooks/useDataSource';
import type { DataSource, NewDataSourceItem } from '../types';
import { getNewDataSourceName } from '../utils/DataSourceUtils';
import { getStyles } from './NewTokenDataSource.styles';
import { TokenDataSource } from './TokenDataSource';

enum DataSourceState {
  CREATE = 0,
  EXISTING = 1,
  LOADING = 2,
  ERROR = 3,
  NOT_AVAILABLE = 4,
}
export function NewTokenDataSource({ item, token }: { item: NewDataSourceItem; token: TokenWithValue }): JSX.Element {
  const [addDataSource, { isError: isAddError, error: addError, isLoading: isAddLoading }] = useAddDataSourceMutation();
  const {
    data: defaultDataSource,
    isLoading: isDefaultDataSourceLoading,
    isError: isGetDefaultDataSourceError,
  } = useGetDataSourceByUIDQuery(item.uid);
  const newTokenName = getNewDataSourceName(item.label, token.name);
  const existingDataSource = useDataSource(newTokenName);
  const dataSourceState = (() => {
    if (existingDataSource) {
      return DataSourceState.EXISTING;
    }

    if (isDefaultDataSourceLoading || isAddLoading) {
      return DataSourceState.LOADING;
    }

    if (isAddError) {
      return DataSourceState.ERROR;
    }

    if (isGetDefaultDataSourceError) {
      return DataSourceState.NOT_AVAILABLE;
    }

    if (defaultDataSource) {
      return DataSourceState.CREATE;
    } else {
      return DataSourceState.NOT_AVAILABLE;
    }
  })();

  function getErrorMessage(): string {
    const error = addError as DataSourceQueryError;
    switch (error?.status) {
      case 409:
        return `Error: data source '${newTokenName}' already exists.`;
      default:
        return `Error: ${error?.data?.message}`;
    }
  }

  function onCreate(ds: DataSource) {
    addDataSource({
      newDataSourceItem: item,
      defaultDataSource: ds,
      authPassword: token.token,
      tokenName: token.name,
    });
  }

  switch (dataSourceState) {
    case DataSourceState.EXISTING:
      return <TokenDataSource dataSource={existingDataSource!} />;
    case DataSourceState.LOADING:
      return <NewTokenDataSourceMessage item={item} message={`Loading...`} />;
    case DataSourceState.CREATE:
      return <NewTokenDataSourceCreate item={item} onCreate={() => onCreate(defaultDataSource!)} />;
    case DataSourceState.ERROR:
      return <NewTokenDataSourceMessage item={item} message={getErrorMessage()} />;
    case DataSourceState.NOT_AVAILABLE:
      return (
        <NewTokenDataSourceMessage
          item={item}
          message="Cannot create a data source, default data source is not available."
        />
      );
  }
}

function NewTokenDataSourceCreate({ item, onCreate }: { item: NewDataSourceItem; onCreate: () => void }): JSX.Element {
  const styles = useStyles2(getStyles);

  return (
    <div className={styles.row}>
      <div>{item.label}</div>
      <div>
        No data source for this scope yet.
        <Button size="sm" variant="secondary" className={styles.button} onClick={onCreate}>
          Create data source
        </Button>
      </div>
    </div>
  );
}

function NewTokenDataSourceMessage({ item, message }: { item: NewDataSourceItem; message: string }): JSX.Element {
  const styles = useStyles2(getStyles);

  return (
    <div className={styles.row}>
      <div>{item.label}</div>
      <div>{message}</div>
    </div>
  );
}
