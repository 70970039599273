import { buildCreateApi, coreModule, reactHooksModule } from '@reduxjs/toolkit/query/react';
import React from 'react';
import { ReactReduxContextValue, createDispatchHook, createSelectorHook, createStoreHook } from 'react-redux';

export const AppApiContext = React.createContext({} as ReactReduxContextValue);

export const createApi = buildCreateApi(
  coreModule(),
  reactHooksModule({
    useDispatch: createDispatchHook(AppApiContext),
    useSelector: createSelectorHook(AppApiContext),
    useStore: createStoreHook(AppApiContext),
  })
);
