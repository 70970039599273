import { useEffect, useState } from 'react';
export function usePageBodyElement(ct: HTMLElement | null): Element | undefined | null {
  const [pageBodyElement, setPageBodyElement] = useState<Element | undefined | null>();
  useEffect(() => {
    if (ct) {
      const pageBodyEl = ct.parentElement?.closest('.page-body');
      if (pageBodyEl) {
        setPageBodyElement(pageBodyEl);
      }
    }
  }, [ct]);

  return pageBodyElement;
}
