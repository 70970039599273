import { css } from '@emotion/css';
import React, { useEffect, useRef } from 'react';

import { Provider } from 'react-redux';

import { AccessPolicies, AccessPoliciesProvider, RealmTypes } from '@grafana-cloud/access-policies';
import { GrafanaComApiProvider } from '@grafana-cloud/grafana-com-api';
import { AppRootProps } from '@grafana/data';
import { config } from '@grafana/runtime';
import { useStyles2 } from '@grafana/ui';
import { AuthPluginTokensTableColumns } from 'feature/data-source/components/AuthPluginTokensTableColumns';
import { AppApiStore } from './feature/common/api/ApiStore';
import { AppApiContext } from './feature/common/api/AppApiContext';
import { PluginPage } from './feature/common/components/PluginPage';
import { Redirect } from './feature/common/components/Redirect';
import { usePageBodyElement } from './feature/common/hooks/usePageBodyElement';
import { getMainNavigationItem, hasAccess } from './feature/common/utils/navUtils';
import { trackEvent } from './feature/common/utils/trackEvent';
import { DeleteAccessPolicy } from './feature/data-source/components/DeleteAccessPolicy';
import { DeleteToken } from './feature/data-source/components/DeleteToken';
import { PluginTokenViewField } from './feature/data-source/components/PluginTokenViewField';

export const getStyles = () => ({
  pageBody: css`
    flex: 1;
    > div,
    > div > div {
      height: 100%;
    }
  `,
});

export function App(props: AppRootProps): JSX.Element {
  const stackId = props.meta?.jsonData?.['stack_id'] ?? '';
  const region = props.meta?.jsonData?.['region'] ?? '';
  const styles = useStyles2(getStyles);
  const ct = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (!config.featureToggles.topnav && !!props.onNavChanged) {
      const main = getMainNavigationItem(props);
      props.onNavChanged({
        main,
        node: main,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // add flex box style to page body
  const pageBodyElement = usePageBodyElement(ct.current);
  if (pageBodyElement) {
    pageBodyElement.classList.add(styles.pageBody);
  }

  return hasAccess() ? (
    <GrafanaComApiProvider config={{ baseApiUrl: 'api/plugin-proxy/grafana-auth-app/auth' }}>
      <AccessPoliciesProvider
        config={{
          realm: { realmType: RealmTypes.STACK, realmIdentifier: String(stackId) },
          region,
          track: trackEvent,
          components: {
            TokenViewField: PluginTokenViewField,
            TokensTableColumns: AuthPluginTokensTableColumns,
            DeleteAccessPolicy,
            DeleteToken,
            AccessPoliciesHeader: () => <></>,
          },
        }}
      >
        <Provider store={AppApiStore} context={AppApiContext}>
          <div ref={ct}>
            <PluginPage>
              <AccessPolicies />
            </PluginPage>
          </div>
        </Provider>
      </AccessPoliciesProvider>
    </GrafanaComApiProvider>
  ) : (
    <Redirect url="/"></Redirect>
  );
}
