import { ScopeAreas } from '@grafana-cloud/access-policies';

import { DataSourceType, DefaultDataSourceUID as DefaultDataSourceUIDs, NewDataSourceItem } from '../types';

export const newDataSourceItems: NewDataSourceItem[] = [
  {
    uid: DefaultDataSourceUIDs[DataSourceType.Prometheus],
    label: 'Prometheus',
    type: DataSourceType.Prometheus,
    scope: ScopeAreas.METRICS,
  },
  {
    uid: DefaultDataSourceUIDs[DataSourceType.Graphite],
    label: 'Graphite',
    type: DataSourceType.Graphite,
    scope: ScopeAreas.METRICS,
  },
  {
    uid: DefaultDataSourceUIDs[DataSourceType.Traces],
    label: 'Traces',
    type: DataSourceType.Traces,
    scope: ScopeAreas.TRACES,
  },
  {
    uid: DefaultDataSourceUIDs[DataSourceType.Logs],
    label: 'Logs',
    type: DataSourceType.Logs,
    scope: ScopeAreas.LOGS,
  },
];
