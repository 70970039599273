import React from 'react';

import { AccessPolicy, TokenViewField, TokenWithValue } from '@grafana-cloud/access-policies';
import { NewTokenDataSources } from './NewTokenDataSources';

export function PluginTokenViewField({
  token,
  accessPolicy,
}: {
  token: TokenWithValue;
  accessPolicy: AccessPolicy;
}): JSX.Element {
  return (
    <>
      <TokenViewField token={token} accessPolicy={accessPolicy} />
      <NewTokenDataSources token={token} accessPolicy={accessPolicy} />
      <br />
    </>
  );
}
