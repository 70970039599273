import groupBy from 'lodash/groupBy';

import { AppApi } from '../../common/api/AppApi';
import { AddDataSourcePayload, DataSource, DataSourceMap, DeleteDataSourceResponse } from '../types';
import { getDataSourceTokenTypeId, getNewDataSourceFromPayload } from '../utils/DataSourceUtils';

interface GetDataSourcesQueryResult {
  dataSources: DataSource[];
  dataSourcesByTokenTypeID: DataSourceMap;
}

export interface DataSourceQueryError {
  status: number;
  data: {
    message: string;
  };
}

const dataSourcesTagType = 'datasources';
const dataSourcesUrl = 'api/datasources';

export const DataSourceApi = AppApi.enhanceEndpoints({
  addTagTypes: [dataSourcesTagType],
}).injectEndpoints({
  endpoints: (build) => ({
    getDataSources: build.query<GetDataSourcesQueryResult, void>({
      query: () => dataSourcesUrl,
      providesTags: [dataSourcesTagType],
      transformResponse: (dataSources: DataSource[]) => {
        return {
          dataSources,
          dataSourcesByTokenTypeID: groupBy(dataSources, (ds) =>
            getDataSourceTokenTypeId(ds.jsonData.tokenName, ds.type)
          ),
        };
      },
    }),
    getDataSourceByUID: build.query<DataSource, string>({
      query: (uid: string) => dataSourcesUrl + `/uid/${uid}`,
    }),
    addDataSource: build.mutation<DataSource, AddDataSourcePayload>({
      query(payload) {
        return {
          url: dataSourcesUrl,
          method: 'POST',
          body: getNewDataSourceFromPayload(payload),
        };
      },
      invalidatesTags: [dataSourcesTagType],
    }),
    deleteDataSourceByUID: build.mutation<DeleteDataSourceResponse[], string[]>({
      queryFn: async (uids: string[], _queryApi, _extraOptions, baseQuery) => {
        const results: DeleteDataSourceResponse[] = [];
        for (const uid of uids) {
          const response = await baseQuery({
            url: `${dataSourcesUrl}/uid/${uid}`,
            method: 'DELETE',
          });

          if (!!response.error) {
            return response;
          }
          results.push(response.data as DeleteDataSourceResponse);
        }
        return { data: results };
      },
      invalidatesTags: [dataSourcesTagType],
    }),
  }),
});

export const {
  useGetDataSourcesQuery,
  useAddDataSourceMutation,
  useGetDataSourceByUIDQuery,
  useDeleteDataSourceByUIDMutation,
} = DataSourceApi;
