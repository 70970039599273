import React from 'react';

import {
  Token,
  TokensTableColumnIDs,
  TokensTableColumns,
  TokensTableColumnsType,
} from '@grafana-cloud/access-policies';
import { TokenDataSources } from './TokenDataSources';

export function AuthPluginTokensTableColumns({
  onDeleteToken,
}: {
  onDeleteToken: (token: Token) => void;
}): TokensTableColumnsType {
  const defaultColumns = TokensTableColumns({ onDeleteToken });

  return {
    [TokensTableColumnIDs.name]: defaultColumns.name,
    [TokensTableColumnIDs.id]: defaultColumns.id,
    [TokensTableColumnIDs.createdAt]: defaultColumns.createdAt,
    [TokensTableColumnIDs.lastUsedAt]: defaultColumns.lastUsedAt,
    [TokensTableColumnIDs.expiresAt]: defaultColumns.expiresAt,
    ['datasources']: {
      header: 'Auto-created data sources',
      cell: (token) => <TokenDataSources token={token} />,
    },
    // @ts-ignore
    // overriding defaultColumns.delete as we want to show it at the end
    [TokensTableColumnIDs.delete]: defaultColumns.delete,
  };
}
