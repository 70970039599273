import { css } from '@emotion/css';

export const getStyles = () => ({
  container: css`
    display: flex;
    justify-content: space-between;
    padding: 2px;
  `,
  label: css`
    text-decoration: italic;
    max-width: 500px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  `,
  link: css`
    text-decoration: underline;
  `,
});
