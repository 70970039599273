import { useMemo } from 'react';

import type { Token, TokenWithValue } from '@grafana-cloud/access-policies';

import { useGetDataSourcesQuery } from '../api/DataSourceApi';
import type { DataSource } from '../types';

export function useTokenDataSources(token: Token | TokenWithValue): DataSource[] {
  const { data: queryResult } = useGetDataSourcesQuery();
  const tokenDataSources = useMemo(
    () => queryResult?.dataSources?.filter((ds) => ds.jsonData?.tokenName === token.name),
    [queryResult, token.name]
  );

  return tokenDataSources ?? [];
}
