import { AccessPolicy, ScopeAreas } from '@grafana-cloud/access-policies';

import { newDataSourceItems } from '../const/NewDataSourceItems';
import type { AddDataSourcePayload, DataSource, DataSourceMap, DataSourceType } from '../types';

export function isExistingDataSource(
  dataSourceType: DataSourceType,
  tokenName: string,
  dataSourcesByTokenTypeID: DataSourceMap | undefined
): boolean {
  return !!dataSourcesByTokenTypeID?.[getDataSourceTokenTypeId(tokenName, dataSourceType)]?.[0];
}

export function isScopeSupportedByPolicy(accessPolicy: AccessPolicy, scope: ScopeAreas): boolean {
  return !!accessPolicy.scopes.read[scope];
}

export function areNewDataSourcesSupportedInPolicy(accessPolicy: AccessPolicy): boolean {
  return newDataSourceItems.some((data) => isScopeSupportedByPolicy(accessPolicy, data.scope));
}

export function getNewDataSourceName(label: string, tokenName: string): string {
  return `Cloud ${label}: ${tokenName}`;
}

export function getDataSourceTokenTypeId(tokenName: string, dataSourceType: string) {
  return tokenName + '/' + dataSourceType;
}
export function getNewDataSourceFromPayload(payload: AddDataSourcePayload): Partial<DataSource> {
  return {
    name: getNewDataSourceName(payload.newDataSourceItem.label, payload.tokenName),
    url: payload.defaultDataSource.url,
    type: payload.defaultDataSource.type,
    access: 'proxy',
    basicAuth: true,
    basicAuthUser: payload.defaultDataSource.basicAuthUser,
    jsonData: {
      tokenName: payload.tokenName,
    },
    secureJsonData: {
      basicAuthPassword: payload.authPassword,
    },
  };
}
