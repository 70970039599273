import React from 'react';

import type { Token, TokenWithValue } from '@grafana-cloud/access-policies';

import { useTokenDataSources } from '../hooks/useTokenDataSources';
import { TokenDataSource } from './TokenDataSource';

export function TokenDataSources({ token }: { token: Token | TokenWithValue }): JSX.Element {
  const tokenDataSources = useTokenDataSources(token);
  return (
    <>
      {tokenDataSources.length > 0
        ? tokenDataSources?.map((ds) => <TokenDataSource key={token.name + '-' + ds.id} dataSource={ds} />)
        : 'No data sources were created when this token was generated.'}
    </>
  );
}
