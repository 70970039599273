import { css } from '@emotion/css';
import React, { useEffect, useState } from 'react';

import { GrafanaTheme2 } from '@grafana/data';
import { Alert, ConfirmModal, useStyles2 } from '@grafana/ui';

import { AccessPolicy, AccessPolicyDelayInfo, useDeleteAccessPolicyMutation } from '@grafana-cloud/access-policies';
import { QueryError } from 'feature/common/types/QueryError';
import { useDeleteDataSourceByUIDMutation } from '../api/DataSourceApi';
import { useAccessPolicyDataSources } from '../hooks/useAccessPolicyDataSources';
import { DataSourcesWarning } from './DataSourcesWarning';

const getStyles = (theme: GrafanaTheme2) => ({
  modalDescription: css`
    margin-top: ${theme.spacing(1)};
  `,
});

interface Props {
  accessPolicy: AccessPolicy;
  onClose: () => void;
}

export const DeleteAccessPolicy = ({ accessPolicy, onClose }: Props) => {
  const styles = useStyles2(getStyles);
  const [deleteAccessPolicy, { isLoading, isSuccess, error }] = useDeleteAccessPolicyMutation();
  const dataSources = useAccessPolicyDataSources(accessPolicy);
  const [confirmDeleteDataSources, setConfirmDeleteDataSources] = useState<boolean>(false);
  const [deleteDataSources, { isLoading: deleting }] = useDeleteDataSourceByUIDMutation();

  function onConfirm() {
    if (!isLoading) {
      deleteAccessPolicy({
        accessPolicy,
      });
      if (dataSources?.length > 0 && confirmDeleteDataSources) {
        deleteDataSources(dataSources.map((ds) => ds.uid));
      }
    }
  }

  useEffect(() => {
    if (isSuccess) {
      onClose();
    }
  }, [isSuccess, onClose]);

  return (
    <ConfirmModal
      isOpen={true}
      title="Delete access policy"
      body="Are you sure you want to delete this access policy?"
      description={
        error ? (
          <Alert title="error" severity="error">
            {(error as QueryError).data.message}
          </Alert>
        ) : (
          <div className={styles.modalDescription}>
            <span>Removing this access policy will remove all its tokens. </span>
            <AccessPolicyDelayInfo />
            <br />
            <DataSourcesWarning
              dataSources={dataSources}
              onConfirmDeleteDataSources={setConfirmDeleteDataSources}
              confirmDeletion={confirmDeleteDataSources}
            />
          </div>
        )
      }
      confirmText={isLoading || deleting ? 'Deleting...' : 'Delete'}
      dismissText="Cancel"
      onDismiss={() => onClose()}
      onConfirm={() => onConfirm()}
    />
  );
};
