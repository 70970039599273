import React from 'react';

import { useStyles2 } from '@grafana/ui';

import type { DataSource } from '../types';
import { getStyles } from './TokenDataSource.styles';
export function TokenDataSource({ dataSource }: { dataSource: DataSource }): JSX.Element {
  const styles = useStyles2(getStyles);
  return (
    <div className={styles.container}>
      <span className={styles.label}>&quot;{dataSource.name}&quot;</span>
      <a className={styles.link} rel="noreferrer" href={'datasources/edit/' + dataSource.id} target="_blank">
        Data source settings
      </a>
    </div>
  );
}
