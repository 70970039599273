import { css } from '@emotion/css';

export const getStyles = () => ({
  row: css`
    display: flex;
    justify-content: space-between;
    padding: 2px;
  `,
  button: css`
    margin-left: 10px;
  `,
});
