import { AppRootProps, NavLinkDTO, } from '@grafana/data';

export function getMainNavigationItem(props: AppRootProps) {
  const navId = 'grafanaCloudAccessPolicies';
  const newNavId = 'standalone-plugin-page-/a/grafana-auth-app';
  const bootData = (window as any).grafanaBootData as any;
  const cfgNav = bootData.navTree.find(({ id }: { id: string }) => id === 'cfg');
  const cfgNavItem = cfgNav?.children?.find(({ id }: { id: string }) => id === navId || id === newNavId);

  // If the running Grafana's cfg nav has an entry for the plugin, render itself as a
  // config page. Otherwise, render as a standalone plugin.
  return cfgNavItem
    ? {
      ...cfgNav,
      children: cfgNav.children.map((item: NavLinkDTO) =>
        item.id === cfgNavItem.id ? { ...item, active: true } : item
      ),
    }
    : {
      text: props.meta.name,
      subTitle: props.meta.info.description,
      url: props.path,
      img: props.meta.info.logos.large,
      children: [
        {
          id: 'access-policies',
          text: 'Access policies',
          icon: 'key-skeleton-alt',
          active: true,
          url: '',
        },
      ],
    };
}

export const hasAccess = (): boolean => {
  // replace with https://github.com/grafana/grafana/pull/89047/files once it's released
  const writePermission = (window as any)?.grafanaBootData?.user?.permissions?.['grafana-auth-app:write'];
  return writePermission === true;
};
