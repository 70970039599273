import React from 'react';

import type { AccessPolicy, TokenWithValue } from '@grafana-cloud/access-policies';

import { useNewDataSourceItems } from '../hooks/useNewDataSourceItems';
import { NewTokenDataSource } from './NewTokenDataSource';
export function NewTokenDataSources({
  accessPolicy,
  token,
}: {
  accessPolicy: AccessPolicy;
  token: TokenWithValue;
}): JSX.Element {
  const items = useNewDataSourceItems(accessPolicy);
  return (
    <>
      {items.map((item) => {
        return <NewTokenDataSource key={item.uid} item={item} token={token} />;
      })}
    </>
  );
}
