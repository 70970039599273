import { useMemo } from 'react';

import { useGetDataSourcesQuery } from '../api/DataSourceApi';
import type { DataSource } from '../types';

export function useDataSource(dataSourceName: string): DataSource | undefined {
  const { data: queryResult } = useGetDataSourcesQuery();
  const dataSource = useMemo(
    () => queryResult?.dataSources?.find((ds) => ds.name === dataSourceName),
    [queryResult, dataSourceName]
  );

  return dataSource;
}
