import { css } from '@emotion/css';
import React, { useContext, useEffect, useState } from 'react';

import { GrafanaTheme2 } from '@grafana/data';
import { Alert, ConfirmModal, useStyles2 } from '@grafana/ui';

import {
  AccessPoliciesContext,
  AccessPolicyDelayInfo,
  Token,
  useDeleteTokenMutation,
} from '@grafana-cloud/access-policies';
import { QueryError } from 'feature/common/types/QueryError';

import { DataSourcesWarning } from '../../data-source/components/DataSourcesWarning';
import { useTokenDataSources } from '../../data-source/hooks/useTokenDataSources';
import { useDeleteDataSourceByUIDMutation } from '../api/DataSourceApi';

const getStyles = (theme: GrafanaTheme2) => ({
  modalDescription: css`
    margin-top: ${theme.spacing(1)};
  `,

  modal: css`
    width: 500px;
  `,
});

export interface DeleteTokenProps {
  token: Token;
  onClose: () => void;
}

export const DeleteToken = ({ token, onClose }: DeleteTokenProps) => {
  const styles = useStyles2(getStyles);
  const { region } = useContext(AccessPoliciesContext);
  const [deleteToken, { isLoading, isSuccess, error }] = useDeleteTokenMutation();
  const tokenDataSources = useTokenDataSources(token);
  const [confirmDeleteDataSources, setConfirmDeleteDataSources] = useState<boolean>(false);
  const [deleteDataSources, { isLoading: deleting }] = useDeleteDataSourceByUIDMutation();

  function onConfirm() {
    if (!isLoading) {
      deleteToken({
        tokenId: token.id,
        region,
      });
      if (tokenDataSources?.length > 0 && confirmDeleteDataSources) {
        deleteDataSources(tokenDataSources.map((ds) => ds.uid));
      }
    }
  }

  useEffect(() => {
    if (isSuccess) {
      onClose();
    }
  }, [isSuccess, onClose]);

  return (
    <ConfirmModal
      isOpen={true}
      title="Delete token"
      body="Are you sure you want to delete this token?"
      modalClass={styles.modal}
      description={
        error ? (
          <Alert title="error" severity="error">
            {(error as QueryError).data.message}
          </Alert>
        ) : (
          <div className={styles.modalDescription}>
            <span>You will no longer be able to use this token. </span>
            <AccessPolicyDelayInfo />
            <DataSourcesWarning
              dataSources={tokenDataSources}
              onConfirmDeleteDataSources={setConfirmDeleteDataSources}
              confirmDeletion={confirmDeleteDataSources}
            />
          </div>
        )
      }
      confirmText={isLoading || deleting ? 'Deleting...' : 'Delete'}
      dismissText="Cancel"
      onDismiss={onClose}
      onConfirm={onConfirm}
    />
  );
};
