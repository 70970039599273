import { configureStore } from '@reduxjs/toolkit';
import { TypedUseSelectorHook, createDispatchHook, createSelectorHook } from 'react-redux';
import { AppApi } from './AppApi';
import { AppApiContext } from './AppApiContext';

export const AppApiStore = configureStore({
  reducer: {
    [AppApi.reducerPath]: AppApi.reducer,
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(AppApi.middleware),
});

export type AppDispatch = typeof AppApiStore.dispatch;
export type AppState = ReturnType<typeof AppApiStore.getState>;
export const useAppDispatch = createDispatchHook<AppState>(AppApiContext);
export const useAppSelector: TypedUseSelectorHook<AppState> = createSelectorHook(AppApiContext);
