import { useMemo } from 'react';

import { AccessPolicy, useGetTokensQuery } from '@grafana-cloud/access-policies';
import { useGetDataSourcesQuery } from '../api/DataSourceApi';
import type { DataSource } from '../types';

export function useAccessPolicyDataSources(accessPolicy: AccessPolicy): DataSource[] {
  const realm = accessPolicy.realms[0];
  const { data } = useGetTokensQuery({
    accessPolicyRealmType: realm.type,
    accessPolicyRealmIdentifier: realm.identifier,
    region: accessPolicy.region!,
  });
  const tokens = data?.tokensByAccessPolicyId[accessPolicy.id];

  const { data: queryResult } = useGetDataSourcesQuery();

  const dataSourcesLinkToTokens = useMemo(
    () =>
      queryResult?.dataSources?.filter(
        (ds) => !!ds.jsonData?.tokenName && tokens?.some((t) => t.name === ds.jsonData.tokenName)
      ),
    [queryResult, tokens]
  );

  return dataSourcesLinkToTokens ?? [];
}
