import { fetchBaseQuery } from '@reduxjs/toolkit/dist/query';
import { createApi } from './AppApiContext';

export const AppApi = createApi({
  baseQuery: fetchBaseQuery({ baseUrl: '' }),
  reducerPath: 'app',
  endpoints: () => ({}),
});

export const api = AppApi;
