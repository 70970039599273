import { ScopeAreas } from '@grafana-cloud/access-policies';

export interface DataSource {
  id: number;
  uid: string;
  orgId: number;
  name: string;
  type: string | DataSourceType;
  typeName: string;
  typeLogoUrl: string;
  access: string;
  url: string;
  password: string;
  basicAuthUser?: string;
  user: string;
  database: string;
  basicAuth: boolean;
  isDefault: boolean;
  jsonData: TokenDataSourceJSONData | any;
  secureJsonData?: {
    basicAuthPassword?: string;
  };
  readOnly: boolean;
}
export interface TokenDataSourceJSONData {
  tokenName: string;
}
export interface NewDataSourceItem {
  uid: string;
  label: string;
  type: DataSourceType;
  scope: ScopeAreas;
}

export enum DataSourceType {
  Prometheus = 'prometheus',
  Graphite = 'graphite',
  Traces = 'tempo',
  Logs = 'loki',
}

export const DefaultDataSourceUID = {
  [DataSourceType.Prometheus]: 'grafanacloud-prom',
  [DataSourceType.Graphite]: 'grafanacloud-graphite',
  [DataSourceType.Traces]: 'grafanacloud-traces',
  [DataSourceType.Logs]: 'grafanacloud-logs',
};

export interface AddDataSourcePayload {
  newDataSourceItem: NewDataSourceItem;
  defaultDataSource: DataSource;
  authPassword: string;
  tokenName: string;
}

export interface DataSourceMap {
  [scope: string]: DataSource[];
}

export interface DeleteDataSourceResponse {
  id: number;
  message: string;
}
