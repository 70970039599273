import { useMemo } from 'react';

import { AccessPolicy } from '@grafana-cloud/access-policies';

import { newDataSourceItems } from '../const/NewDataSourceItems';
import type { NewDataSourceItem } from '../types';
import { isScopeSupportedByPolicy } from '../utils/DataSourceUtils';

export function useNewDataSourceItems(accessPolicy: AccessPolicy): NewDataSourceItem[] {
  const items = useMemo<NewDataSourceItem[]>(
    () => newDataSourceItems.filter((item) => isScopeSupportedByPolicy(accessPolicy, item.scope)),
    [accessPolicy]
  );

  return items;
}
